/* TODO Add site wide styles */

html,
#root {
  /* height: 100% !important; */
  /* for some reason removing this makes it actually expand all */
  width: 100%;
  overflow-y: scroll;
  /* background-color: rgb(18, 18, 18); */
}
* {
  font-family: Circular;
}
body {
  overscroll-behavior: none;
  /* prevent overscrolling on top spotify navbar */
}

html,
body {
  max-width: 100% !important;
  /* overflow-x: hidden !important; */
}

input:focus {
  outline: none !important;
}

/* ===== Scrollbar CSS ===== */
/* creds to Stephen Paton https://codepen.io/stephenpaton-tech/full/JjRvGmY */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #666666 #121212;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #4e4c4c;
}

*::-webkit-scrollbar-thumb {
  background-color: #d2c7c7;
  border-radius: 0px;
  border: 0px solid #a6a6a6;
}
.player-wrapper {
  overflow: auto;
}

#smallDevice {
  position: fixed;
  padding: 0;
  margin: 0;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}
