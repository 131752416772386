/* .homecontainer {
  width: 150px;
} */

.navbar {
  position: fixed;
  z-index: 100;
}

.globalwrapper {
  height: 100%;
  background-color: rgb(18, 18, 18);
}

/* To remove a scrollbar while maintaining functionality */
.globalwrapper::-webkit-scrollbar {
  display: none;
}

.outermostwrapper::-webkit-scrollbar {
  display: none;
}

#root::-webkit-scrollbar {
  display: none;
}

#root {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.emptyQuery {
  margin-top: 25vh;
  font-size: 1.6rem;
  text-align: center;
  user-select: none;
}
.emptyQuery2 {
  margin-top: 10vh;
  font-size: 1.6rem;
  text-align: center;
  user-select: none;
}

/* Three dots bouncing animations, credits to mattonit @ https://codepen.io/mattonit/pen/vLoddq ; note: had to convert from scss to css*/

div#wave {
  position: relative;
  margin-top: 27vh;
  text-align: center;
  width: 200px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}
div#wave .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 3px;
  background: #ffffff;
  animation: wave 1.3s linear infinite;
}
div#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}
div#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}
@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }
  30% {
    transform: translateY(-15px);
  }
}

.lowerSongs:hover .lowerAddPlaylistButtons {
  visibility: visible;
}

.temptemp {
  background-color: rgb(42, 42, 42);
  transform: translate(2px);

  transition: ease 0.2s;
}

.temptemp2 {
  /* background-color: rgb(83, 83, 83); */
  transform: scale(1.02);
  transition: ease 0.3s;
}
